import React from "react";
import ".././index.scss";
import { getStartEndDateFromWeek } from "./Utils/WeekHelper";
import moment from "moment";
import { AcpService } from "../../../service/ACPService";

const WeekRange = ({
  // setWeekStartRange,
  setWeekEndRange,
  identifier,
  weekRanges,
  setFilterSubmitButton,
  filters,
  setFilters,
  setOpenEndWeekRange,
  setOpenStartWeekRange,
}) => {

  const selectWeekRange = (weekRangeValue) => {
    if (identifier == "start") {
      setFilterSubmitButton(false);
      let updatedValue = {};
      const { startDate } = getStartEndDateFromWeek(weekRangeValue);
      const newStartDate = AcpService.getFormattedDate(startDate);

      updatedValue = { weekStartRange: weekRangeValue, startDate: moment(newStartDate).format("YYYYMMDDTHHmmss.SSS [GMT]") };
      setFilters((prev) => {
        return { ...prev, ...updatedValue };
      });
      setOpenStartWeekRange(false);
      //setWeekStartRange(weekRangeValue);
    } else if (identifier == "end") {
      setFilterSubmitButton(false);
      let updatedValue = {};
      const { endDate, startDate } = getStartEndDateFromWeek(weekRangeValue);
      const newEndDate = AcpService.getFormattedDate(endDate);
      const newStartDateForEndDate = AcpService.getFormattedDate(startDate);

      updatedValue = { weekEndRange: weekRangeValue, endDate: moment(newEndDate).format("YYYYMMDDTHHmmss.SSS [GMT]"), endStartDate: moment(newStartDateForEndDate).format("YYYYMMDDTHHmmss.SSS [GMT]") };
      setFilters((prev) => {
        return { ...prev, ...updatedValue };
      });
      //setWeekEndRange(weekRangeValue);
      setOpenEndWeekRange(false);
    }
  };

  return (
    <>
      <div className="weekRangeDataWraper">
        {weekRanges.map((range, index) => (
          <p
            onClick={() => {
              selectWeekRange(range);
            }}
            key={index}
            className="weekRangeData"
          >
            {range}
          </p>
        ))}
      </div>
    </>
  );
};

export default WeekRange;
