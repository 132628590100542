// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-list-table-container {
  padding: 1rem 0rem 1.5rem 1rem;
}
.file-list-table-container .file-list-table {
  width: max-content;
  min-width: 100%;
}
.file-list-table-container .file-list-table .file-list-header {
  padding: 0.834rem 1.34rem;
  color: #595959;
  font-weight: 500;
  line-height: normal;
  background: var(--Grey-for-background, #fafafa);
  border: 0;
}
.file-list-table-container .file-list-table .file-list-header:first-child {
  border-radius: 0.66rem 0 0 0;
}
.file-list-table-container .file-list-table .file-list-header:last-child {
  border-radius: 0 0.66rem 0 0;
}
.file-list-table-container .file-list-table .file-list-header.file-list-header-date {
  padding: 0.834rem;
}
.file-list-table-container .file-list-table .file-list-data-row {
  border: 0;
  overflow: hidden;
  color: var(--Primary-color, var(--Primary-Color, #003da5));
  text-overflow: ellipsis;
  line-height: normal;
  padding: 1rem;
}
.file-list-table-container .file-list-table .file-list-data-row:nth-child(1) {
  width: 40%;
}
.file-list-table-container .file-list-table .file-list-data-row:nth-child(2) {
  width: 18%;
}
.file-list-table-container .file-list-table .file-list-data-row:nth-child(3) {
  width: 20%;
}
.file-list-table-container .file-list-table .file-list-data-row .file-replace-input {
  display: none;
}
.file-list-table-container .file-list-table .file-list-data-row .file-options-icon-cta {
  color: var(--Primary-color, var(--Primary-Color, #003da5));
}
.file-list-table-container .file-list-table .file-list-data-row .file-options-icon-cta span {
  color: var(--Primary-color, var(--Primary-Color, #003da5));
}
.file-list-table-container .file-list-table .file-list-data-row .version-active {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/common/FileUploadManager/FileList.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;AACJ;AACI;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,+CAAA;EACA,SAAA;AACN;AACM;EACE,4BAAA;AACR;AAEM;EACE,4BAAA;AAAR;AAGM;EACE,iBAAA;AADR;AAKI;EAEE,SAAA;EACA,gBAAA;EACA,0DAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAJN;AAMM;EACE,UAAA;AAJR;AAOM;EACE,UAAA;AALR;AAQM;EACE,UAAA;AANR;AASM;EACE,aAAA;AAPR;AAUM;EACE,0DAAA;AARR;AAUQ;EACE,0DAAA;AARV;AAYM;EACE,eAAA;AAVR","sourcesContent":[".file-list-table-container {\n  padding: 1rem 0rem 1.5rem 1rem;\n\n  .file-list-table {\n    width: max-content;\n    min-width: 100%;\n\n    .file-list-header {\n      padding: 0.834rem 1.34rem;\n      color: #595959;\n      font-weight: 500;\n      line-height: normal;\n      background: var(--Grey-for-background, #fafafa);\n      border: 0;\n\n      &:first-child {\n        border-radius: 0.66rem 0 0 0;\n      }\n\n      &:last-child {\n        border-radius: 0 0.66rem 0 0;\n      }\n\n      &.file-list-header-date {\n        padding: 0.834rem;\n      }\n    }\n\n    .file-list-data-row {\n      // width: 25%;\n      border: 0;\n      overflow: hidden;\n      color: var(--Primary-color, var(--Primary-Color, #003da5));\n      text-overflow: ellipsis;\n      line-height: normal;\n      padding: 1rem;\n\n      &:nth-child(1) {\n        width: 40%;\n      }\n\n      &:nth-child(2) {\n        width: 18%;\n      }\n\n      &:nth-child(3) {\n        width: 20%;\n      }\n\n      .file-replace-input {\n        display: none;\n      }\n\n      .file-options-icon-cta {\n        color: var(--Primary-color, var(--Primary-Color, #003da5));\n\n        span {\n          color: var(--Primary-color, var(--Primary-Color, #003da5));\n        }\n      }\n\n      .version-active {\n        cursor: pointer;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
