import React from "react";
import "./CustomCalender.scss";
import WeekRange from "../WeekRange";
import arrow from "../../../../assets/images/expandCollapseAll.svg";

const CustomCalender = ({
  setWeekEndRange,
  setWeekStartRange,
  identifier,
  months,
  currMonth,
  currYear,
  prevNextHandler,
  weekRanges,
  setFilterSubmitButton,
  filters,
  setFilters,
  setOpenEndWeekRange,
  setOpenStartWeekRange,
}) => {
  return (
    <div className=" customCalender ">
      <header>
        <div>
          {" "}
          <span
            id="prev"
            className="material-symbols-rounded leftArrow"
            onClick={() => prevNextHandler("prev")}
          >
            <img src={arrow} alt="leftArrow" />
          </span>
        </div>
        <div>
          {identifier === "start" ?
            <p className="current-date">{`${months[currMonth]} ${currYear}`}</p> : <p className="current-date">{`${months[currMonth]} ${currYear}`}</p>}
        </div>
        <div>
          <span
            id="next"
            className="material-symbols-rounded rightArrow"
            onClick={() => prevNextHandler("next")}
          >
            <img src={arrow} alt="rightArrow" />
          </span>
        </div>
      </header>
      <div className="calendar">
        <div className="current-date">
          {
            <WeekRange
              setWeekStartRange={setWeekStartRange}
              setWeekEndRange={setWeekEndRange}
              weekRanges={weekRanges}
              identifier={identifier}
              setFilterSubmitButton={setFilterSubmitButton}
              filters={filters}
              setFilters={setFilters}
              setOpenEndWeekRange={setOpenEndWeekRange}
              setOpenStartWeekRange={setOpenStartWeekRange}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default CustomCalender;
