
import { months } from "../components/ACP/constant";
import { ProjectService } from "./PegaService";
import moment from "moment";
import { getStartEndDateFromWeek, getWeekRange } from "../components/ACP/Planning/Utils/WeekHelper";
import { useLocation } from "react-router-dom";

export const AcpService = {
  formatDate(date) {
    if (!date) return;
    const formattedDate = new Date(
      moment(date, "YYYYMMDD[T]HHmmss.SSS [GMT]")
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
    );
    if (formattedDate.getFullYear() > "1999") {
      return formattedDate;
    } else {
      return "";
    }
  },

  convertDate(inputDate) {
    const parsedStartDate = moment(inputDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
    const convertedStartDate = parsedStartDate.format("YYYYMMDDTHHmmss.SSS [GMT]");
    return convertedStartDate;
  },

  groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  },

  reorderColumns(columns) {
    const requiredColumnOrderArray = ProjectService.getAvailableBookingColumnNames();
    let reorderedColumns = [];
    requiredColumnOrderArray?.forEach((rcl) => {
      columns?.forEach((cl) => {
        if (rcl === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  },

  formatDuration(duration) {
    if (parseInt(duration) < 10) {
      return "0" + parseInt(duration);
    } else {
      return parseInt(duration);
    }
  },

  addRefinedOrder(inputArray) {
    let keys = Object.keys(inputArray);
    keys.sort((a, b) => inputArray[a].order - inputArray[b].order);
    let outputArray = {};
    keys.forEach((key, index) => {
      outputArray[key] = {
        ...inputArray[key],
        refinedOrder: index + 1,
        key: key,
      };
    });
    return outputArray;
  },

  countTasksByGroup(inputArray) {
    const groupCounts = {};
    inputArray.forEach((task) => {
      const group = task.Group;
      const groupOrder = task.GroupOrder;
      if (group in groupCounts) {
        groupCounts[group].count += 1;
      } else {
        groupCounts[group] = { count: 1, order: groupOrder };
      }
    });
    return groupCounts;
  },

  formatTableDate(inputDateString) {
    if (inputDateString) {
      const parsedDate = moment(inputDateString, "YYYYMMDDTHHmmss.SSS [GMT]");
      const formattedDate = parsedDate.format("DD-MMM");
      return formattedDate;
    } else {
      return inputDateString;
    }
  },

  getDatesInRange(startDateStr, endDateStr) {
    const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const dates = [];

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      if (weekdays.includes(currentDate.toString().split(" ")[0])) {
        const dateStr = `${
          currentDate.getDate() < 10 ? "0" + currentDate.getDate() : currentDate.getDate()
        }-${currentDate.toLocaleString("default", {
          month: "short",
        })}`;
        dates.push(dateStr);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  },

  calculateDateDifference(startDate, endDate) {
    if (!startDate || !endDate) {
      return false;
    }
    let currentDate = new Date(startDate);
    const targetDate = new Date(endDate);
    let dateDifference = 0;

    const direction = currentDate < targetDate ? 1 : -1;
    while (
      (direction === 1 && currentDate < targetDate) ||
      (direction === -1 && currentDate > targetDate)
    ) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 5 && dayOfWeek !== 6) {
        // Exclude Saturday (6) and Sunday (0)
        dateDifference += direction; // Adjusting for direction
      }
      currentDate.setDate(currentDate.getDate() + direction); // Move to the next or previous day based on direction
    }

    // If the end date is a Saturday or Sunday, adjust the difference
    if (targetDate.getDay() === 5 || targetDate.getDay() === 6) {
      dateDifference -= direction;
    }
    return dateDifference;
  },

  convertToGMT(inputDateString, rowData, tabName) {
    const inputDate = moment(inputDateString);
    let nextDay;
    if (tabName === "booking") {
      if (this.formatTableDate(inputDateString) === this.formatTableDate(new Date())) {
        nextDay = inputDate;
      } else {
        nextDay = inputDate.add(1, "days");
      }
    } else if (tabName === "bveLoop") {
      if (this.formatTableDate(inputDateString) === this.formatTableDate(rowData.StartDate)) {
        nextDay = inputDate;
      } else {
        nextDay = inputDate.add(1, "days");
      }
    }

    const parsedDate = moment(nextDay, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
    const convertedDate = parsedDate.format("YYYYMMDDTHHmmss.SSS [GMT]");
    return convertedDate;
  },

  checkDateIsSameToFilterDate(startDate, filteredStartDate) {
    const taskStartDate = moment(startDate, "YYYYMMDDTHHmmss.SSS Z").format("DD-MM-YY");
    const filterStartDate = moment(filteredStartDate, "YYYYMMDDTHHmmss.SSS Z").format("DD-MM-YY");
    return moment(taskStartDate).isSame(filterStartDate) || taskStartDate === filterStartDate;
  },

  filterObjectsByDate(arr, startDate1, startDate2) {
    const filteredArray = arr?.filter((obj) => {
      return this.isDateInRange(obj.StartDate, startDate1, startDate2);
    });
    return filteredArray;
  },
  addWeekdays(startDate, days) {
    const weekdays = [];
    let currentDate = new Date(startDate);
    while (weekdays.length < days) {
      let nextDate = new Date(currentDate);
      nextDate.setDate(nextDate.getDate() + 1);
      if (nextDate.getDay() !== 0 && nextDate.getDay() !== 6) {
        weekdays.push(nextDate);
      }
      currentDate = nextDate;
    }
    return weekdays[weekdays.length - 1];
  },
  addBusinessDays(startDateStr, days) {
    const startDate = new Date(startDateStr);

    let daysToAdd = 0;
    if (days === 1) {
      daysToAdd = 0; // Same day
    } else if (days === 3) {
      daysToAdd = 2; // Next 2 working days
    } else if (days === 7) {
      daysToAdd = 6; // Next 6 working days
    } else if (days === 14) {
      daysToAdd = 13; // Next 13 working days
    } else {
      throw new Error("Invalid input. Days must be 1, 3, 7, or 14.");
    }

    let totalDaysAdded = 0; // Counter for weekdays added
    let currentDate = new Date(startDate); // To manipulate and add days

    // Add business days
    while (totalDaysAdded < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      // Check if the current date is a weekday (Monday to Friday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        totalDaysAdded++;
      }
    }
    return currentDate;
  },

  reorderTaskListColumns(columns) {
    const requiredColumnOrderArray = ProjectService.getAvailablePlanningColumnNames();
    let reorderedColumns = [];
    requiredColumnOrderArray?.forEach((rcl) => {
      columns?.forEach((cl) => {
        if (rcl === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  },

  reorderProjectListColumns(columns) {
    const requiredColumnOrderArray = ProjectService.getAvailablePlanningProjectColumnNames();
    let reorderedColumns = [];
    requiredColumnOrderArray?.forEach((rcl) => {
      columns?.forEach((cl) => {
        if (rcl === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  },

  slackDataCalculation(options, field, value) {
    const newStartDate = options.StartDate;
    if (!options.tempStartDate) {
      options.tempStartDate = newStartDate;
    }

    const startDate =
      !this.isUBN(newStartDate) && !this.checkIfValidDate(newStartDate)
        ? this.formatTableDate(newStartDate)
        : this.formatTableDate(new Date(newStartDate));
    const endDate = this.formatTableDate(value);
    options[field] = value;
    const dateDifference = this.calculateDateDifference(startDate, endDate);
    if (!options.tempSlack && options.tempSlack !== 0) {
      options.Slack = options.Slack !== "" ? options.Slack : 0;
      options.tempSlack = options.Slack;
      options.Slack = options.tempSlack + dateDifference;
    } else {
      options.Slack = options.tempSlack + dateDifference;
    }
    return options;
  },
  changeDateFromISTtoUTC(dateStr) {
    const parsedStartDate = moment(dateStr, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
    const convertedStartDate = parsedStartDate.format("YYYYMMDDTHHmmss.SSS [GMT]");
    return convertedStartDate;
  },
  findUniqueObjects(array1, array2, key) {
    const mergedArray = [...array1, ...array2];
    const uniqueObjectsMap = new Map();
    mergedArray.forEach((obj) => {
      const keyValue = obj[key];
      if (!uniqueObjectsMap.has(keyValue)) {
        uniqueObjectsMap.set(keyValue, obj);
      }
    });
    const uniqueArray = Array.from(uniqueObjectsMap.values());
    return uniqueArray;
  },
  handleDateChange(options, field, value, isSlack, setEditedRow, allEditedRows, tabName) {
    options.isSelected = true;
    if (!options.tempStartDate) {
      options.tempStartDate = options.StartDate;
    }
    const startDate =
      !AcpService.isUBN(options?.tempStartDate) &&
      !AcpService.checkIfValidDate(options?.tempStartDate)
        ? AcpService.formatTableDate(options?.tempStartDate)
        : AcpService.formatTableDate(new Date(options?.tempStartDate));
    const endDate = AcpService.formatTableDate(value);
    options[field] = value;
    const dateDifference = this.calculateDateDifference(startDate, endDate);
    if (!options.existGroupOrder && options.existGroupOrder !== 0) {
      const newSlack = parseInt(options.Slack, 10);
      options.existGroupOrder = newSlack;
      const SlackResult = parseInt(options.existGroupOrder, 10) + dateDifference;
      options.Slack = String(SlackResult).padStart(2, "0");
    } else {
      const SlackResult = parseInt(options.existGroupOrder, 10) + dateDifference;
      options.Slack = String(SlackResult).padStart(2, "0");
    }

    setEditedRow(options);
    if (isSlack !== "slackValue" || isSlack === undefined) {
      options[field] = this.convertDate(value, options, tabName);
    }
    if (allEditedRows?.length) {
      allEditedRows?.forEach((row) => {
        if (row.TaskName === options.TaskName) {
          row[field] = this.convertDate(value, options, tabName);
          // row["edited"] = true;
        }
      });
    }
  },
  checkWeekend(dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6) - 1;
    const day = dateString.slice(6, 8);

    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();

    return dayOfWeek === 0 || dayOfWeek === 6;
  },
  getUrl(env, endpoint) {
    let url = `${env}/${endpoint}`;
    return url;
  },
  getConvertedDate(date) {
    const parsedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
    const convertedDate = parsedDate.format("YYYYMMDDTHHmmss.SSS [GMT]");
    return convertedDate;
  },

  getFormattedDate(date) {
    if (!isNaN(date)) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
    return date;
  },
  getInitialFilterState() {
    let dailyStartDate = new Date();
    let dailyEndDate = new Date();
    dailyEndDate.setDate(dailyEndDate.getDate() + 10);
    const weekData = getWeekRange();
    const firstWeek = weekData[0];
    const secondWeek = weekData[1];
    const { startDate } = getStartEndDateFromWeek(firstWeek);
    const { endDate } = getStartEndDateFromWeek(secondWeek);
    const newStartDate = this.getFormattedDate(startDate);
    const newEndDate = this.getFormattedDate(endDate);
    let endMonth = new Date();
    endMonth.setMonth(endMonth.getMonth() + 7);

    const initialFilterState = {
      selectedOption: "Weekly",
      selectedTask: "All Tasks",
      selectedSupplier: "Select Supplier",
      weekStartRange: firstWeek,
      weekEndRange: secondWeek,
      dailyStartDate: dailyStartDate,
      dailyEndDate: dailyEndDate,
      monthStart: months[dailyStartDate.getMonth()],
      monthEnd: months[endMonth.getMonth()],
      startDate: this.getConvertedDate(newStartDate),
      endDate: this.getConvertedDate(newEndDate),
    };

    return initialFilterState;
  },

  getMonthDates(month) {
    const months = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    const today = new Date();
    const year = today.getFullYear();
    const firstDay = new Date(year, months[month], 1);
    const lastDay = new Date(year, months[month] + 1, 0);

    const pad = (num) => (num < 10 ? "0" : "") + num;

    const formatDate = (date) =>
      `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`;

    const startDate = formatDate(firstDay);
    const endDate = formatDate(lastDay);

    return {
      start: startDate,
      end: endDate,
    };
  },

  getCellClassName(rowData, column) {
    const value = rowData[column.field];
    return value < 0 ? "negativeValue" : "";
  },

  isDateInRange(dateToCheck, startDate, endDate) {
    if (typeof dateToCheck !== "string") {
      if (dateToCheck instanceof Date) {
        dateToCheck = dateToCheck.toISOString().slice(0, 10).replace(/-/g, "");
      }
    }

    const dateToCheckDatePart = dateToCheck?.includes("T")
      ? dateToCheck?.split("T")[0]
      : dateToCheck;
    const formattedDateToCheck = new Date(
      dateToCheckDatePart?.slice(0, 4),
      parseInt(dateToCheckDatePart?.slice(4, 6)) - 1,
      dateToCheckDatePart?.slice(6, 8)
    );

    const dateStr = String(startDate);
    const year = dateStr.slice(0, 4);
    const month = parseInt(dateStr.slice(4, 6)) - 1;
    const day = dateStr.slice(6, 8);
    const formattedStartDate = new Date(year, month, day);

    const endDateStr = String(endDate);
    const endYear = endDateStr.slice(0, 4);
    const endMonth = parseInt(endDateStr.slice(4, 6)) - 1;
    const endDay = endDateStr.slice(6, 8);
    const formattedEndDate = new Date(endYear, endMonth, endDay);

    return formattedDateToCheck >= formattedStartDate && formattedDateToCheck <= formattedEndDate;
  },
  formatDateForDailyRange(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    return `${month} ${day}`;
  },

  getAcpFilterGlobal(
    value,
    multiplesFlag,
    artworkCategories,
    filterArrayP,
    selectedColumnListP,
    setFilters,
    colAllSelectedP,
    selectedFieldsRefP,
    colName
  ) {
    if (filterArrayP.current?.length) {
      if (value?.length > 0 && !multiplesFlag) {
        let filterProjectState = selectedColumnListP.current?.filter((item) => {
          if (item) {
            // Check if the selected values include the item directly (for text values)
            const hasValue = artworkCategories.includes(item[colName]);
            // If it's not a direct match for text, format the date and check for matches
            if (!hasValue) {
              const formattedDate = moment(item[colName], "YYYYMMDDTHHmmss.SSS [GMT]").format(
                "DD-MMM-YY"
              );
              return artworkCategories.includes(formattedDate);
            }
            return hasValue;
          }
          return false;
        });
        setFilters(filterProjectState);
        selectedColumnListP.current = filterProjectState;
      } else {
        for (let k = 0; k < colAllSelectedP.current?.length; k++) {
          let filterProjectState = selectedColumnListP.current?.filter((item) => {
            if (item && item[colAllSelectedP.current[k]]) {
              const hasValue = selectedFieldsRefP.current[0][colAllSelectedP.current[k]]?.includes(
                item[colAllSelectedP.current[k]]
              );
              if (!hasValue) {
                const formattedDate = moment(
                  item[colAllSelectedP.current[k]],
                  "YYYYMMDDTHHmmss.SSS [GMT]"
                ).format("DD-MMM-YY");
                return selectedFieldsRefP.current[0][colAllSelectedP.current[k]]?.includes(
                  formattedDate
                );
              }
              return hasValue;
            }
            return false;
          });
          selectedColumnListP.current = filterProjectState;
          setFilters(filterProjectState);
        }
      }
    } else {
      selectedColumnListP.current = [];
      setFilters([]);
    }
  },

  checkDatesInRange(date1, date2, date3, date4) {
    const dateRegex = /^\d{8}$/;

    if (
      !dateRegex.test(date1) ||
      !dateRegex.test(date2) ||
      !dateRegex.test(date3) ||
      !dateRegex.test(date4)
    ) {
      return "Dates should be in the format YYYYMMDD.";
    }

    // Convert dates to Date objects for comparison
    const d1 = new Date(`${date1.substr(0, 4)}-${date1.substr(4, 2)}-${date1.substr(6, 2)}`);
    const d2 = new Date(`${date2.substr(0, 4)}-${date2.substr(4, 2)}-${date2.substr(6, 2)}`);
    const d3 = new Date(`${date3.substr(0, 4)}-${date3.substr(4, 2)}-${date3.substr(6, 2)}`);
    const d4 = new Date(`${date4.substr(0, 4)}-${date4.substr(4, 2)}-${date4.substr(6, 2)}`);

    return d1 > d3 && d1 < d4 && d2 > d3 && d2 < d4;
  },
  isWeekend(dateString) {
    if (!this.isUBN(dateString)) {
      const year = parseInt(dateString.substring(0, 4));
      const month = parseInt(dateString.substring(4, 6)) - 1; // Month is zero-based
      const day = parseInt(dateString.substring(6, 8));

      const date = new Date(year, month, day);
      const dayOfWeek = date.getDay(); // 0 for Sunday, 6 for Saturday

      return dayOfWeek === 0 || dayOfWeek === 6; // Return true if it is Sunday or Saturday
    }
  },
  isUBN(data) {
    return data === undefined || data === "" || data === null;
  },
  isWeekendUTC(dateString) {
    if (!this.isUBN(dateString)) {
      const year = parseInt(dateString.substring(0, 4));
      const month = parseInt(dateString.substring(4, 6)) - 1; // Months are zero-based in JavaScript
      const day = parseInt(dateString.substring(6, 8));

      const date = new Date(Date.UTC(year, month, day));

      const dayOfWeek = date.getUTCDay();

      return dayOfWeek === 0 || dayOfWeek === 6; // 0 is Sunday, 6 is Saturday
    }
  },
  removeCommonValues(firstArray, secondArray) {
    const secondSet = new Set(secondArray);
    const resultArray = firstArray?.filter((item) => !secondSet?.has(item));
    return resultArray;
  },
  isNegative(value) {
    return value < 0;
  },
  getProjectMinDate(optionsVal) {
    let startDate;
    let minDate;
    if (optionsVal?.ProjectState && optionsVal?.ProjectStartDate) {
      startDate =
        !this.isUBN(optionsVal?.ProjectStartDate) &&
        !this.checkIfValidDate(optionsVal?.ProjectStartDate)
          ? this.formatDate(optionsVal?.ProjectStartDate)
          : optionsVal?.ProjectStartDate;
      if (optionsVal?.ProjectState?.toLowerCase() === "active") {
        minDate = startDate < new Date() ? new Date() : startDate;
      } else if (optionsVal?.ProjectState?.toLowerCase() === "draft") {
        minDate = startDate > new Date() ? startDate : new Date();
      }
    }
    return minDate ? minDate : new Date();
  },
  getMinDate(optionsVal) {
    let initialDate;
    if (optionsVal?.EstimatedAWReadiness) {
      initialDate =
        !this.isUBN(optionsVal?.EstimatedAWReadiness) &&
        !this.checkIfValidDate(optionsVal?.EstimatedAWReadiness)
          ? this.formatDate(optionsVal?.EstimatedAWReadiness)
          : optionsVal?.EstimatedAWReadiness;
    } else {
      initialDate = optionsVal?.NewStartDate
        ? new Date(this.formatDate(optionsVal?.NewStartDate))
        : !this.isUBN(optionsVal?.StartDate) && !this.checkIfValidDate(optionsVal?.StartDate)
        ? this.formatDate(optionsVal?.StartDate)
        : optionsVal?.StartDate;
    }
    let minDate;

    // Calculate the date with the Slack value, skipping weekends
    if (initialDate) {
      minDate = new Date(initialDate);
      let slack = optionsVal?.Slack || 0;

      // Adjust the date backward by Slack days while skipping weekends
      while (slack > 0) {
        minDate.setDate(minDate.getDate() - 1);
        const dayOfWeek = minDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          slack -= 1;
        }
      }

      // Ensure the final date does not fall on a weekend
      const finalDayOfWeek = minDate.getDay();
      if (finalDayOfWeek === 6) {
        minDate.setDate(minDate.getDate() - 1); // Move to Friday
      } else if (finalDayOfWeek === 0) {
        minDate.setDate(minDate.getDate() - 2); // Move to Friday
      }
    }
    return minDate ? minDate : null;
  },
  getDateDifference(firstDate, secondDate) {
    return new Date(this.formatDate(firstDate)) - new Date(this.formatDate(secondDate));
  },
  convertArrayToUniqueObjects(tableData) {
    let result = {};

    tableData?.forEach((item) => {
      for (let key in item) {
        if (result[key]) {
          result[key] = Array.from(new Set([...result[key], ...item[key]]));
        } else {
          result[key] = item[key];
        }
      }
    });

    let filteredColumns = [];
    for (let key in result) {
      let obj = {};
      obj[key] = result[key];
      filteredColumns.push(obj);
    }

    return filteredColumns;
  },
  filterArrays(tableData, filteredColumns, setConditionMatched) {
    setConditionMatched(false);
    let filters2 = {};
    filteredColumns?.forEach((filter) => {
      for (let key in filter) {
        if (filter.hasOwnProperty(key)) {
          filters2[key] = filter[key];
        }
      }
    });

    // Check if any item in tableData satisfies all filter conditions
    let allConditionsMet = tableData?.some((item) => {
      return Object.keys(filters2)?.every((filterKey) => {
        // If the item does not have the filter key, return false
        if (!item.hasOwnProperty(filterKey)) {
          return false;
        }
        // If the filter key is in the filters2 object, check if the item value is in the filter values
        return filters2[filterKey]?.includes(item[filterKey]);
      });
    });

    // If any condition is not met, return an empty array
    if (!allConditionsMet) {
      setConditionMatched(true);
      return [];
    }

    // Filter tableData based on the filters2
    return tableData?.filter((item) => {
      return Object.keys(filters2)?.every((filterKey) => {
        return filters2[filterKey]?.includes(item[filterKey]);
      });
    });
  },
  convertToShortDateFormat(inputDate, format) {
    if (!inputDate) return "";
    // Parse the input date and format it to 'DD-MMM-YY'
    const formattedDate = moment(inputDate, "YYYYMMDDTHHmmss.SSS").format(
      !this.isUBN(format) ? format : "DD-MMM-YY"
    );

    return formattedDate;
  },

  formatDateConvert(inputDateStr) {
    if (!isNaN(inputDateStr)) {
      return false;
    }
    const year = inputDateStr.slice(0, 4);
    const month = inputDateStr.slice(4, 6);
    const day = inputDateStr.slice(6, 8);
    const hours = inputDateStr.slice(9, 11);
    const minutes = inputDateStr.slice(11, 13);
    const seconds = inputDateStr.slice(13, 15);

    // Create a Date object with extracted components
    const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`);
    const fullYear = date.getUTCFullYear();

    return { year: fullYear, month: date.getUTCMonth() };
  },
  emptyProperty(obj, propertyName) {
    if (obj.hasOwnProperty(propertyName)) {
      obj[propertyName] = [];
    }
    return obj;
  },
  filterDataByfilters(tblData, _selectedFieldsRef, setConditionMatched, tabName) {
    let _selectedFieldsRefCurrent = this.convertArrayToUniqueObjects(_selectedFieldsRef);
    if (tabName === "booking") {
      localStorage.setItem(
        "_selectedFieldsRefCurrentBooking",
        JSON.stringify(_selectedFieldsRefCurrent)
      );
    } else if (tabName === "planning") {
      localStorage.setItem(
        "_selectedFieldsRefCurrentPlanning",
        JSON.stringify(_selectedFieldsRefCurrent)
      );
    } else if (tabName === "DashboardPanelTop") {
      localStorage.setItem(
        "_selectedFieldsRefCurrentDashboardPanelTop",
        JSON.stringify(_selectedFieldsRefCurrent)
      );
    } else if (tabName === "DashboardPanelBottom") {
      localStorage.setItem(
        "_selectedFieldsRefCurrentDashboardPanelBottom",
        JSON.stringify(_selectedFieldsRefCurrent)
      );
    } else if (tabName === "myTasks") {
      localStorage.setItem(
        "_selectedFieldsRefCurrentMyTasks",
        JSON.stringify(_selectedFieldsRefCurrent)
      );
    } else if (tabName === "allTasks") {
      localStorage.setItem(
        "_selectedFieldsRefCurrentAllTasks",
        JSON.stringify(_selectedFieldsRefCurrent)
      );
    } else if (tabName === "artworkStatus") {
      localStorage.setItem(
        "_selectedFieldsRefCurrentArtworkStatus",
        JSON.stringify(_selectedFieldsRefCurrent)
      );
    } else if (tabName === "myProjects") {
      localStorage.setItem("_selectedFieldsRefCurrentMyProjects",
      JSON.stringify(_selectedFieldsRefCurrent)
      );
    }
    else if (tabName === "All Projects") {
      localStorage.setItem("_selectedFieldsRefCurrentAllProjects",
      JSON.stringify(_selectedFieldsRefCurrent)
      );
    }
    else if (tabName === "ArtworkAlignment") {
      localStorage.setItem("_selectedFieldsRefCurrentArtworkAlignment",
      JSON.stringify(_selectedFieldsRefCurrent)
      );
    }

    const selectedColumnListWithDateFormat = tblData?.map((el) => {
      return {
        ...el,
        EndDate: this.checkIfValidDate(el?.EndDate)
          ? el?.EndDate
          : !this.isUBN(el?.EndDate)
          ? this.convertToShortDateFormat(el?.EndDate)
          : "",
        StartDate: this.checkIfValidDate(el?.StartDate)
          ? el?.StartDate
          : !this.isUBN(el?.StartDate)
          ? this.convertToShortDateFormat(el?.StartDate)
          : "",
        ArtPrinterDate: this.checkIfValidDate(el?.ArtPrinterDate)
          ? el?.ArtPrinterDate
          : !this.isUBN(el?.ArtPrinterDate)
          ? this.convertToShortDateFormat(el?.ArtPrinterDate)
          : "",
        AwRedinessDate: this.checkIfValidDate(el?.AwRedinessDate)
          ? el?.AwRedinessDate
          : !this.isUBN(el?.AwRedinessDate)
          ? this.convertToShortDateFormat(el?.AwRedinessDate)
          : "",
        PmName: el?.PmName?.name ? el?.PmName?.name : el?.PmName,
      };
    });
    const filteredArray = this.filterArrays(
      selectedColumnListWithDateFormat,
      _selectedFieldsRefCurrent,
      setConditionMatched
    );
    return filteredArray;
  },
  checkIfValidDate(inputDate) {
    return inputDate?.length < 20;
  },
  getDaysInMonth(inputDate, setCalendarData) {
    const startDate = new Date(inputDate);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysList = [];

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");
      const milliseconds = currentDate.getMilliseconds().toString().padStart(3, "0");
      const formattedDate = `${year}${month}${day}T${hours}${minutes}${seconds}.${milliseconds}Z`;
      // const dayOfWeek = currentDate.getDay();
      const dayOfWeek = new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(currentDate);

      daysList.push({ Date: formattedDate, Day: dayOfWeek, DayAsNumber: currentDate.getDay() });

      currentDate.setDate(currentDate.getDate() + 1);
    }
    setCalendarData(daysList);
    return daysList;
  },
  getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7) === 53
      ? 1
      : Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  },
  getWeekRangesForMonth(dateString, setTableData) {
    const inputDate = new Date(dateString);
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    // Start with the first day of the month
    let currentDay = new Date(year, month, 1);
    if (currentDay.getDay() === 0 || currentDay.getDay() === 6) {
      currentDay.setDate(currentDay.getDate() + ((1 + 7 - currentDay.getDay()) % 7 || 7));
    }
    // // Find the first Monday on or before the first day of the month
    while (currentDay.getDay() !== 1) {
      currentDay.setDate(currentDay.getDate() - 1);
    }

    const weekRanges = [];
    const cwList = [];
    // Loop to find all week ranges in the given month
    do {
      const weekStart = new Date(currentDay);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6); // Get the Sunday of this week

      // Add the week only if it has at least one day in the current month
      if (weekStart.getMonth() === month || weekEnd.getMonth() === month) {
        weekRanges.push({
          week: this.getWeekNumber(weekStart),
          dateS: new Date(weekStart), // YYYY-MM-DD format for Monday
          dateE: new Date(weekEnd), // YYYY-MM-DD format for Sunday
        });

        cwList.push(
          this.getWeekNumber(weekStart) < 10
            ? "CW 0" + this.getWeekNumber(weekStart)
            : "CW " + this.getWeekNumber(weekStart)
        );
      }

      // Move to the next Monday
      currentDay.setDate(currentDay.getDate() + 7);
    } while (
      currentDay.getMonth() === month ||
      (currentDay.getDay() !== 1 && currentDay.getMonth() === month + 1)
    );
    setTableData(weekRanges);
    return cwList;
  },

  sortDataAlphaNumeric(data, key, direction = "asc") {
    const compareFunction = (a, b) => {
      let valueA = key ? a[key] : a;
      let valueB = key ? b[key] : b;

      // Ensure values are defined and handle null/undefined/empty strings
      const isBlank = (value) => value === null || value === undefined || value === "";

      if (isBlank(valueA) && !isBlank(valueB)) return 1;
      if (!isBlank(valueA) && isBlank(valueB)) return -1;
      if (isBlank(valueA) && isBlank(valueB)) return 0;

      // Check if the value is a ProjectID format (e.g., "AWM-83")
      const isProjectID = (value) =>
        typeof value === "string" &&
        value?.includes("-") &&
        !isNaN(parseInt(value?.split("-")[1], 10));

      if (isProjectID(valueA) && isProjectID(valueB)) {
        // If both values are ProjectID, compare their numeric parts
        const idA = parseInt(valueA?.split("-")[1], 10);
        const idB = parseInt(valueB?.split("-")[1], 10);

        if (direction === "asc") {
          return idA - idB;
        } else {
          return idB - idA;
        }
      } else if (!isNaN(valueA) && !isNaN(valueB)) {
        // For numeric values, compare as numbers
        valueA = Number(valueA);
        valueB = Number(valueB);

        if (direction === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      } else {
        // For general strings (e.g., ProjectName), compare case-insensitively
        valueA = String(valueA)?.toLowerCase();
        valueB = String(valueB)?.toLowerCase();

        if (direction === "asc") {
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
        } else if (direction === "desc") {
          if (valueA > valueB) {
            return -1;
          }
          if (valueA < valueB) {
            return 1;
          }
          return 0;
        } else {
          throw new Error('Invalid direction. Use "asc" or "desc".');
        }
      }
    };
    return data?.sort(compareFunction);
  },
  convertShortDateToGMT(dateString) {
    // Parse the input date string
    const parsedDate = moment(dateString, "DD-MMM-YY", true);

    // Check if the date string is valid
    if (!parsedDate.isValid()) {
      return "Invalid date format";
    }

    // Convert the date to the desired GMT format
    const gmtDateString = parsedDate.format("YYYYMMDDTHHmmss.SSS [GMT]");

    return gmtDateString;
  },
  sortDataFirstStringThenNumeric(data, key, direction = "asc") {
    const compareFunction = (a, b) => {
      let valueA =
        key === "EndDate" ||
        key === "StartDate" ||
        key === "ArtPrinterDate" ||
        key === "AwRedinessDate"
          ? !this.checkIfValidDate(a[key])
            ? a[key]
            : this.convertShortDateToGMT(a[key])
          : a[key];
      let valueB =
        key === "EndDate" ||
        key === "StartDate" ||
        key === "ArtPrinterDate" ||
        key === "AwRedinessDate"
          ? !this.checkIfValidDate(b[key])
            ? b[key]
            : this.convertShortDateToGMT(b[key])
          : b[key];

      // Ensure values are defined and handle null/undefined/empty strings
      const isBlank = (value) => value === null || value === undefined || value === "";

      if (isBlank(valueA) && !isBlank(valueB)) return 1;
      if (!isBlank(valueA) && isBlank(valueB)) return -1;
      if (isBlank(valueA) && isBlank(valueB)) return 0;

      const canBeFloat = (value) => !isNaN(parseFloat(value)) && isFinite(value);

      if (canBeFloat(valueA) && canBeFloat(valueB)) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);

        if (direction === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }

      // Check if values are purely numeric
      const isNumeric = (value) => !isNaN(value) && !isNaN(parseFloat(value));

      if (isNumeric(valueA) && !isNumeric(valueB)) {
        return -1; // Numeric values should come before alphanumeric values
      }
      if (!isNumeric(valueA) && isNumeric(valueB)) {
        return 1; // Alphanumeric values should come after numeric values
      }
      // Handle numeric values
      if (isNumeric(valueA) && isNumeric(valueB)) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);

        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }

      // Helper function to split strings into alphanumeric parts
      const splitAlphanumeric = (value) => {
        const regex = /(\d+|\D+)/g;
        return value
          .match(regex)
          .map((part) => (isNaN(part) ? part.toLowerCase() : parseInt(part, 10)));
      };

      if (typeof valueA === "string" && typeof valueB === "string") {
        const partsA = splitAlphanumeric(valueA);
        const partsB = splitAlphanumeric(valueB);

        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
          const partA = partsA[i] !== undefined ? partsA[i] : "";
          const partB = partsB[i] !== undefined ? partsB[i] : "";

          if (partA < partB) return direction === "asc" ? -1 : 1;
          if (partA > partB) return direction === "asc" ? 1 : -1;
        }

        return 0;
      }

      // Check if the value is a ProjectID format (e.g., "AWM-83")
      const isProjectID = (value) =>
        typeof value === "string" &&
        value.includes("-") &&
        !isNaN(parseInt(value?.split("-")[1], 10));

      if (isProjectID(valueA) && isProjectID(valueB)) {
        // If both values are ProjectID, compare their prefixes first, then numeric parts
        const extractParts = (value) => {
          const match = value?.match(/([a-zA-Z]+)-(\d+)/);
          return match
            ? { prefix: match[1]?.toLowerCase(), number: parseInt(match[2], 10) }
            : { prefix: "", number: 0 };
        };

        const partA = extractParts(valueA);
        const partB = extractParts(valueB);

        // First compare by prefix
        if (partA.prefix < partB.prefix) return direction === "asc" ? -1 : 1;
        if (partA.prefix > partB.prefix) return direction === "asc" ? 1 : -1;

        // If prefixes are the same, compare by numeric part
        if (partA.number < partB.number) return direction === "asc" ? -1 : 1;
        if (partA.number > partB.number) return direction === "asc" ? 1 : -1;

        return 0;
      } else if (!isNaN(valueA) && !isNaN(valueB)) {
        // For numeric values, compare as numbers
        valueA = Number(valueA);
        valueB = Number(valueB);

        if (direction === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      } else {
        // For general strings (e.g., ProjectName), compare case-insensitively
        valueA = String(valueA)?.toLowerCase();
        valueB = String(valueB)?.toLowerCase();

        if (direction === "asc") {
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
        } else if (direction === "desc") {
          if (valueA > valueB) {
            return -1;
          }
          if (valueA < valueB) {
            return 1;
          }
          return 0;
        } else {
          throw new Error('Invalid direction. Use "asc" or "desc".');
        }
      }
    };

    return data?.sort(compareFunction);
  },
  convertToObjectArray(input) {
    // Initialize an empty array to store the result
    const result = [];

    // Iterate over each key in the input object
    for (let key in input) {
      // Create an object with the current key and its corresponding array
      const obj = {
        [key]: input[key],
      };

      // Push the object into the result array
      result.push(obj);
    }

    // Return the array of objects
    return result;
  },
  removeEmptyArrays(arr) {
    return arr
      ?.map((obj) => {
        return Object.keys(obj)
          ?.filter((key) => obj?.[key]?.length > 0)
          ?.reduce((newObj, key) => {
            newObj[key] = obj?.[key];
            return newObj;
          }, {});
      })
      ?.filter((obj) => Object.keys(obj)?.length > 0);
  },
  updateStartDateFormat(arr) {
    return arr?.map((item) => {
      if (item?.StartDate) {
        item.StartDate =
          !this.isUBN(item.StartDate) && !this.checkIfValidDate(item.StartDate)
            ? this.formatDate(item.StartDate)
            : this.convertDate(new Date(item?.StartDate));
      }
      return item;
    });
  },

  getMatchDemand(initialDemand, updatedDemand, taskType, selectedfilter) {
    const matchedDemand = initialDemand.some((item) => {
      const demand = item[`${selectedfilter}`];
      return item.taskType === taskType && demand === updatedDemand;
    });
    return matchedDemand;
  },
  getUniqueObjects(arr) {
    const seen = new Set();
    return arr?.filter((obj) => {
      const stringifiedObj = JSON.stringify(obj);
      if (seen.has(stringifiedObj)) {
        return false;
      } else {
        seen.add(stringifiedObj);
        return true;
      }
    });
  },
  areAllPropertiesEmptyArrays(obj) {
    // Check if all properties in the object are arrays and are empty
    return Object.values(obj).every((value) => Array.isArray(value) && value.length === 0);
  },
  filterPMObjects(inputArr) {
    let outputArr = [];
    inputArr?.forEach((obj) => {
      if (obj?.hasOwnProperty("PM") && !AcpService.isUBN(obj["PM"])) {
        outputArr.push({ name: obj.PM, code: obj.PM });
      }
    });

    return outputArr;
  },
  compareDates(dateStr1, dateStr2) {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    return date1 > date2;
  },
  useQuery() {
    return new URLSearchParams(useLocation().search);
  },
  convertToIST(dateString) {
    // Parse the input date string in GMT
    const parsedDate = moment.utc(dateString, "YYYYMMDDTHHmmss.SSS [GMT]");

    // Check if the date string is valid
    if (!parsedDate.isValid()) {
      return "Invalid date format";
    }

    // Set the time to 00:00:00 in IST
    const istDate = parsedDate.startOf("day");

    // Format the date in the desired output
    const istDateString = istDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

    return istDateString;
  },
  toCamelCase(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  },
  decodedParams(query) {
    const params = [
      "Demand",
      "EndDate",
      "TaskType",
      "Supplier",
      "Capacity",
      "DateType",
      "StartDate",
    ];
    return params.reduce((acc, param) => {
      acc[this.toCamelCase(param) + "QueryParams"] = decodeURIComponent(query.get(param) || "");
      return acc;
    }, {});
  },
  formatDateToYearMonth(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string provided");
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}${month}`;
  },
  formatYearMonthToLabel(yearMonth) {
    const yearStr = yearMonth.toString().slice(0, 4);
    const monthStr = yearMonth.toString().slice(4, 6);
    const monthNumber = parseInt(monthStr, 10);
    const monthName = months[monthNumber - 1];
    return `${monthName} ${yearStr}`;
  },
  convertFormatToUTC(inputDate) {
    // Parse the date from the input string
    const [day, monthStr, yearStr] = inputDate.split('-');

    // Map month string to its corresponding number
    const monthMap = {
        'Jan': 0,
        'Feb': 1,
        'Mar': 2,
        'Apr': 3,
        'May': 4,
        'Jun': 5,
        'Jul': 6,
        'Aug': 7,
        'Sep': 8,
        'Oct': 9,
        'Nov': 10,
        'Dec': 11
    };

    const month = monthMap[monthStr];
    const year = parseInt("20" + yearStr, 10); // Assumes the year is in the 2000s

    // Create a new Date object in UTC
    const date = new Date(Date.UTC(year, month, day)); // Adding time as  UTC
    const formattedDate = date.toLocaleString('en-US', date);
    return formattedDate;
},
};
