import "./index.scss";
import TaskDialog from "../../TaskDialog";
import CPPFA from "./../../AWMJobs/CPPFA";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import _, { cloneDeep, isEmpty } from "lodash";
import { Calendar } from "primereact/calendar";
import ProjectPlanTable from "./ProjectPlanTable";
import ACPFilter from "../../ACP/common/ACPFilter";
import { InputNumber } from "primereact/inputnumber";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch, useSelector } from "react-redux";
import filter from "../../../assets/images/filter.svg";
import hyphen from "../../../assets/images/hyphen.svg";
import ApproveDesignDialog from "./ApproveDesignDialog";
import { AcpService } from "../../../service/ACPService";
import messageIcon from "../../../assets/images/Message.svg";
import { getFilterOptions } from "../../../Utils/GridService";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { AcpCommonService } from "../../../service/AcpCommonService";
import React, { useState, useEffect, useRef, Suspense } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { SortAndFilterService } from "../../../service/SortAndFilterService";
import { dipsalyStateValues, extractData, formatIntoDatePicker } from "./util";
import { ArtWorkTabValuesAction } from "../../../store/actions/ArtWorkTabValuesActions";
import { changeDateFormat, onSortData, bufferToWork, multiFilterChange } from "../../../utils";

const ProjectPlanList = (props) => {
  const {
    op,
    ownerVal,
    inputRef,
    dropdownRef,
    filterArray,
    colAllSelected,
    selectedFieldsRef,
    selectedColumnList,
    view,
    filters,
    pegadata,
    isSearch,
    childFunc,
    setFilters,
    allColumns,
    setPegaData,
    setColWidth,
    fieldUpdated,
    projectState,
    setActiveSave,
    isAccessEmpty,
    frozenUpdated,
    selectedFields,
    setFieldUpdated,
    projectDataData,
    setFrozenUpdated,
    setSelectedFields,
    getProjectPlanApi,
    isCustomizeViewActive,
    setInitProjectPlanList,
    columnWiseSelectedFields,
    setColumnWiseSelectedFields,
    handleExapandCollapseAllFilter,
    setUpdatedProjectPlanDesignData,
    resizeMode,
    sortData,
    setSortData,
  } = props;
  let today = new Date();
  let minDate = new Date();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { ProjectID } = useParams();
  minDate.setDate(today.getDate());
  const [flag, setFlag] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const User = useSelector((state) => state.UserReducer);
  const [messageContent, setMessageContent] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [designTabPegaData, setDesignTabPegaData] = useState([]);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const { projectPlan } = useSelector((state) => state.ProjectPlanReducer);
  const [selectedTaskApproveDialog, setSelectedTaskApproveDialog] = useState([]);
  const [width, setWidth] = useState(0);
  const {
    userDetails: { AccessGroup },
  } = useSelector((state) => state?.UserDetailsReducer);

  const roleFormat = () => {
    return AccessGroup?.map((role) => {
      return role?.AccessGroupNames?.substr(4);
    });
  };
  const userInformation = User.userInformation;
  const userDetailsInformation = roleFormat();
  const isUserAccess = userDetailsInformation?.includes("ProjectManager");
  const accessSecurityMatrix = usePriorityNew(location?.pathname.split("/")[1]);
  const finalPermission = isUserAccess ? isUserAccess : accessSecurityMatrix;

  useEffect(() => {
    if (dropdownRef?.current) {
      setWidth(dropdownRef?.current?.offsetWidth);
    }
  }, [pegadata]);

  useEffect(() => {
    fetchData();
  }, [projectPlan]);

  const autoVal = (field, options) => {
    let val;
    if (field === "Role") {
      val = options?.data["Role"];
    } else if (options?.data["Owner"]) {
      if (ownerVal.current === "") {
        val = options?.data["Owner"];
      } else {
        val = ownerVal.current;
      }
    } else {
      const roleItem = options?.data?.RoleOptions?.flatMap(
        (role) => role?.OwnerOptionsNew || []
      ).filter((ele) => ele?.Name === options?.data?.Assignee);
      if (Array.isArray(roleItem) && roleItem.length > 0) {
        val = options?.data["Assignee"];
      } else {
        val = null;
      }
    }
    return val;
  };

  function fetchData() {
    try {
      setPegaData(projectPlan);
      setInitProjectPlanList(projectPlan);
      // according to pathname we need to call api and store column name in local storage
      // let columnNamesJson = localStorage.getItem('projectPlanAllColumnNames');
      // const storageColumnNames = JSON.parse(columnNamesJson);
      // const columnNames = ProjectService.getProjectPlanAllColumnNames();
      // if (storageColumnNames != null && storageColumnNames.length) {
      //   // storageColumnNames.length === columnNames.length
      //   // isEqual(columnNames, storageColumnNames)
      //   if (storageColumnNames.length === columnNames.length) {
      //     let sortedData = [];
      //     sortedData = [...storageColumnNames].sort((a, b) => {
      //       return parseInt(a.sequence) - parseInt(b.sequence);
      //     });
      //   } else {
      //     let sortedData = [];
      //     sortedData = [...columnNames].sort((a, b) => {
      //       return parseInt(a.sequence) - parseInt(b.sequence);
      //     });
      //   }
      // }

      // get sort data from local storage and add in state
      let jsonSortingData1 = localStorage.getItem("projectPlanSortingData");
      const sortingData = JSON.parse(jsonSortingData1);
      if (sortingData && sortingData.length) {
        const sortedData = onSortData(sortingData[0], sortingData[1], projectPlan);
        setPegaData(sortedData);
        setSortData([sortingData[0], sortingData[1]]);
      }

      // get frozen data from local storage and add in state
      let jsonFrozenrData1 = localStorage.getItem("frozenDataProjectPlan");
      const frozenDataProjectPlan = JSON.parse(jsonFrozenrData1);
      if (frozenDataProjectPlan && frozenDataProjectPlan.length) {
        setFrozenColumn(frozenDataProjectPlan);
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      setFrozenColumn(frozenCoulmns);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      setFrozenColumn(frozenCoulmns);
    }
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const projectNameHeader = (options, columns, col) => {
    const optionsCode = getFilterOptions(options);

    return (
      <div className="flex-display" key={options}>
        <img
          src={filter}
          alt="Column Filter"
          key={options}
          onClick={(e) => projectNameOnClick(e, options)}
          className={
            sortData?.includes(options) ||
            selectedFields?.[options]?.[0] ||
            selectedFields?.[options]?.[0] === false ||
            frozenCoulmns?.includes(options)
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        />
        <span
          className={
            sortData?.includes(options) ||
            selectedFields?.[options]?.[0] ||
            selectedFields?.[options]?.[0] === false ||
            frozenCoulmns?.includes(options)
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        >
          {col?.field_Name === "Project_TaskID" || col?.field_Name === "Project_PredecessorID"
            ? columns
            : options}
        </span>
      </div>
    );
  };

  const flatPegadata = pegadata?.flatMap((obj) => extractData(obj));

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      pegadata,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = selectedFieldsRef?.current?.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentProjectPlan");
    }
    if (filterArray?.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected?.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const convertedObj = AcpCommonService.convertArrayToObject(
          _selectedFieldsRefWithUniqueObjects
        );
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);
        let { Task, ...newObj } = uniqueObj;
        let filteredData2 = [];
        let filteredData = [];
        if (Task?.length) {
          const Task2 = {
            Task: Task,
          };
          filteredData = SortAndFilterService.applyMutilabelFilter(pegadata, Task2, filters);
        }
        if (Object.entries(newObj)?.length) {
          filteredData2 = SortAndFilterService.multipleColumnFilter(
            filteredData?.length ? filteredData : pegadata,
            newObj,
            filters
          );
        }
        const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
        setSelectedFields(uniqueObj);
        if (finalFilteredData?.length && sortData?.length) {
          onSort(sortData?.[0], sortData?.[1], finalFilteredData);
        } else {
          setFilters(finalFilteredData);
          selectedColumnList.current = finalFilteredData;
        }
      } else {
        setFilters([]);
        setSelectedFields({});
        selectedFieldsRef.current = [];
        selectedColumnList.current = [];
      }
    } else {
      setFilters([]);
      setSelectedFields({});
      selectedFieldsRef.current = [];
      selectedColumnList.current = [];
    }
  };

  const clearColumnWiseFilter = () => {
    let _selectedColumnName = selectedColumnName;
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortingDataProjectPlanList");
      setSortData([]);
    }

    const selectedFields2 = _.cloneDeep(selectedFields);
    const uniqueObj = AcpCommonService.getUniqueValuesObject(selectedFields2);
    const _selectedFields = AcpService.emptyProperty(uniqueObj, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentProjectPlanList");
    }
    let { Task, ...newObj } = __selectedFields;
    let filteredData2 = [];
    let filteredData = [];
    if (Task?.length) {
      const Task2 = {
        Task: Task,
      };
      filteredData = SortAndFilterService.applyMutilabelFilter(pegadata, Task2, filters);
    }
    if (Object.entries(newObj)?.length) {
      filteredData2 = SortAndFilterService.multipleColumnFilter(
        filteredData?.length ? filteredData : pegadata,
        newObj,
        filters
      );
    }
    const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
    if (finalFilteredData?.length && Object.keys(__selectedFields)?.length) {
      setFilters(finalFilteredData);
    } else {
      setFilters([]);
    }
  };

  const saveSettings = () => {
    localStorage.setItem("columnWiseFilterData", JSON.stringify(filters));
    localStorage.setItem("frozenDataProjectPlan", JSON.stringify(frozenCoulmns));
    localStorage.setItem("sortingData", JSON.stringify(sortData));
    // localStorage.setItem('projectPlanAllColumnNames', JSON.stringify(projectColumnName));
  };

  const handleInfoIconClick = (message, reason) => {
    setMessageContent([message, reason]);
    setShowDialog(true);
  };

  const formatHelpNeeded = (rowData) => {
    let className = "";
    let helpNeeded = rowData["Help_Needed"];
    switch (helpNeeded) {
      case "Yes, In Process":
        className = "helpneeded_inprocess";
        helpNeeded = "Yes, In Process";
        rowData["Help_Needed"] = "Yes, In Process";
        return (
          <>
            <span
              className="helpneeded_inprocess"
              onClick={() =>
                handleInfoIconClick(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
              }
            >
              <img
                className="message_logo"
                src={messageIcon}
                alt="message logo"
                onClick={() =>
                  handleInfoIconClick(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
                }
              />
              {rowData?.Help_Needed}
            </span>
          </>
        );
        break;
      case "Yes, Done":
        className = "helpneeded_done";
        helpNeeded = "Yes, Done";
        break;
      case "N/A":
        className = "helpneeded_no";
        helpNeeded = "N/A";
        break;
      case "No":
        className = "helpneeded_no";
        helpNeeded = "No";
        break;
      default:
        break;
    }
    return <span className={className}>{helpNeeded}</span>;
  };

  const durationEnabled = (State) => {
    if (State === "In-Progress" || State === "Awaiting") {
      return false;
    } else {
      return true;
    }
  };

  const elementTemplate = (options, rowData) => {
    const field = rowData.field;
    const optionsData = options.data;
    const currentUrl = location.pathname;
    let currentUrlLastSeg = currentUrl?.split("/")[3];
    let currentUrlBasePage = currentUrl?.split("/")[1];
    const code = options?.code;
    const keyCode = code?.split("_");
    const locaiton = window.location.pathname;
    const url = `${locaiton.split("/")[1]}/${locaiton.split("/")[2]}/${
      keyCode?.[0]
    }/${code}/${currentUrlLastSeg}`;
    const daysLeftStyle =
      optionsData["Days_Left"] === 0 || optionsData["Days_Left"] === "N/A" ? "" : "red";
    const cursorStyle =
      optionsData["GroupName"]?.includes("Final Art") || optionsData["Task_ID"]?.includes("CPC_")
        ? "default"
        : "";

    const clickHandler = () => {
      if (field && field.length && keyCode[0] === "CPPFA") {
        handleApproveDialogCPPFA(options);
      } else if (
        optionsData["Task_ID"]?.includes("SAA_") ||
        optionsData["Task_ID"]?.includes("TPOA_")
      ) {
        dispatch(ArtWorkTabValuesAction([]));
        navigate(
          `/${currentUrlBasePage}/artworkAlignment/${optionsData["Assignee"]}/${projectDataData?.AWM_Project_ID}`
        );
      } else if (
        optionsData["Task_ID"]?.includes("DM_") ||
        optionsData["Task_ID"]?.includes("RCIC_") ||
        optionsData["Task_ID"]?.includes("RCICMatrix_")
      ) {
        navigate(`/${currentUrlBasePage}/dependencyMapping/${projectDataData?.AWM_Project_ID}`);
      } else if (optionsData["Task_ID"]?.includes("UBD_")) {
        navigate(
          `/${currentUrlBasePage}/projectPlan/UBD/${options.code}/${projectDataData?.AWM_Project_ID}`
        );
      } else if (
        optionsData["Task"] !== "Design" &&
        optionsData["Task"] !== "Input" &&
        !optionsData["GroupName"]?.includes("Final Art") &&
        !optionsData["Task_ID"]?.includes("CPC_") &&
        optionsData["Task"] !== "Final Art" &&
        !optionsData["Task"]?.includes("(X")
      ) {
        (options.redirect === true || optionsData.Task) &&
          optionsData.State !== "Awaiting" &&
          navigate(`../${url}`, { replace: true });
      }
    };

    return (
      <>
        {field === "Task" && (
          <span
            className={`${
              optionsData?.State === "Awaiting"
                ? ""
                : options?.children?.length === 0
                ? "task-link"
                : "task"
            } ${field === "Task" && "custom-toggler-wrapper"}`}
            style={{ cursor: cursorStyle }}
            onClick={clickHandler}
            title={optionsData?.[field]}
          >
            {optionsData[field]}
          </span>
        )}
        {options?.children && options?.children?.length > 0 ? (
          <>
            {(field === "Role" ||
              field === "Owner" ||
              field === "Assignee" ||
              field === "State" ||
              field === "TaskDuration" ||
              field === "StartDate" ||
              field === "TaskID" ||
              field === "Dependency" ||
              field === "Project_TaskID" ||
              field === "Project_PredecessorID" ||
              field === "DaysLeft" ||
              field === "EndDate") && (
              <img
                src={hyphen}
                alt="Hyphen"
                onClick={(e) => {
                  op.current.toggle(e);
                }}
              />
            )}
          </>
        ) : (
          <>
            {field === "Project_TaskID" && (
              <span
                title={optionsData?.["Project_TaskID"]}
                className={`${
                  optionsData?.State === "Awaiting"
                    ? ""
                    : options?.children?.length === 0
                    ? "task-link"
                    : "task"
                }`}
                style={{ cursor: cursorStyle }}
                onClick={clickHandler}
              >
                {optionsData?.["Project_TaskID"]}
              </span>
            )}
            {(field === "Role" || field === "Owner" || field === "Assignee") && (
              <div disabled={!isAccessEmpty} className="d-flex" ref={dropdownRef}>
                <CommonAutocomplete
                  width={width}
                  suggestions={
                    optionsData && field === "Role"
                      ? optionsData?.["RoleOptions"]
                      : optionsData && (field === "Owner" || field === "Assignee")
                      ? optionsData &&
                        optionsData?.["RoleOptions"] &&
                        optionsData?.["RoleOptions"]?.find(
                          (obj) => optionsData?.["Role"] === obj?.Name
                        )?.OwnerOptionsNew
                      : []
                  }
                  value={options.data[field === "Owner" ? "Assignee" : field]}
                  ref={inputRef}
                  onChange={(e) => {
                    onDDTyped(options, e, field);
                  }}
                  onSelect={(e) => {
                    onDropdownChange(options, e, field);
                  }}
                  placeholder={`Select ${field}`}
                  field="Name"
                  disabled={
                    optionsData?.GroupName === "Final Art" ||
                    optionsData?.State?.includes?.("Complete") ||
                    optionsData?.["Task_ID"]?.includes("CPC_") ||
                    projectState === "Complete" ||
                    !finalPermission
                  }
                />
              </div>
            )}
            {field === "State" && dipsalyStateValues(optionsData?.[field])}

            {field === "TaskDuration" && options?.data?.[field] !== "" && (
              <InputNumber
                className="input-duration"
                inputId="integeronly"
                value={options?.data?.[field]}
                onValueChange={(e) => onDurationChange(options, e, field)}
                disabled={
                  !isAccessEmpty ||
                  durationEnabled(optionsData?.State) ||
                  !finalPermission ||
                  projectState === "Complete"
                }
                min={1}
              />
            )}

            {field === "Start_Date" && (
              <div className="projectplan-calender">
                <Calendar
                  value={
                    !AcpService.isUBN(optionsData?.["Start_Date"])
                      ? formatIntoDatePicker(optionsData?.["Start_Date"])
                      : null
                  }
                  onChange={(e) => {
                    onDate(options, e, field);
                  }}
                  dateFormat="d-M-y"
                  showIcon={true}
                  minDate={minDate}
                  disabled={
                    optionsData.State !== "Awaiting" ||
                    !finalPermission ||
                    projectState === "Complete"
                  }
                  icon={<CalendarIcon />}
                />
              </div>
            )}

            {field === "End_Date" && (
              <span>
                {optionsData?.["End_Date"]?.length !== undefined ? optionsData?.["End_Date"] : ""}
              </span>
            )}
            {field === "Days_Left" && optionsData?.["TaskDuration"] !== "" && (
              <span style={{ color: daysLeftStyle }}>{optionsData?.["Days_Left"]}</span>
            )}
            {field === "Project_PredecessorID" && (
              <span title={optionsData?.[field]}> {optionsData?.[field]} </span>
            )}
            {field === "Remaining_Work" && optionsData?.["Remaining_Work"]}
            {field === "Buffer" && optionsData?.["Buffer"]}
            {field === "Buffer_To_Work" &&
              options?.[field] !== "" &&
              bufferToWork(optionsData?.["Buffer_To_Work"])}
          </>
        )}

        {field === "Help_Needed" && formatHelpNeeded(optionsData)}

        {field !== "Task" &&
          field !== "Task_ID" &&
          field !== "Role" &&
          field !== "Owner" &&
          field !== "Assignee" &&
          field !== "State" &&
          field !== "Start_Date" &&
          field !== "End_Date" &&
          field !== "TaskDuration" &&
          field !== "Help_Needed" &&
          field !== "Buffer" &&
          field !== "Dependency" &&
          field !== "Project_TaskID" &&
          field !== "Project_PredecessorID" &&
          field !== "Remaining_Work" &&
          field !== "Buffer_To_Work" &&
          field !== "Days_Left" && <>{optionsData[field]}</>}
      </>
    );
  };

  const updateProjectPlanDesign = () => {
    let arr = [];
    const prepareUpdatedData = (parent, child) => {
      if (!isEmpty(parent) && !isEmpty(child)) {
        arr.push({
          ...child,
          AWM_Project_ID: projectDataData?.AWM_Project_ID,
          AWM_Task_ID: parent.code,
          Task_Name: child.Task,
          Assignee: child.Owner ? child.Owner : child.Assignee,
        });
      }
    };
    pegadata.forEach((obj) => {
      obj.children?.forEach((data) => {
        if (data.children.length === 0) {
          prepareUpdatedData(data, data.data);
        } else if (data.children.length > 0) {
          data.children.forEach((child) => {
            prepareUpdatedData(child, child.data);
            if (child.children.length > 0) {
              child.children.forEach((innerChild) => {
                prepareUpdatedData(innerChild, innerChild.data);
              });
            }
          });
        }
      });
    });
    setUpdatedProjectPlanDesignData(arr);
  };

  useEffect(() => {
    pegadata && updateProjectPlanDesign();
  }, [pegadata]);

  const onDropdownChange = (rowData, { value }, ele) => {
    if (typeof value === "string") {
      if (value.length > 2) {
        //rowData.data[ele] = value;
        if (ele === "Role") {
          if (rowData.data["Role"] !== value) {
            rowData.data["Assignee"] = "";
          }
        }
        if (ele === "Owner") {
          rowData.data["Assignee"] = value;
        } else {
          rowData.data[ele] = value;
        }

        const updatedPegadata = pegadata.map((obj) => {
          obj.children.map((data) => {
            if (data.code === rowData.code) {
              return {
                ...data,
                data: {
                  ...data.data,
                  //[ele]: value,
                  [ele === "Owner" ? "Assignee" : ele]: value,
                },
              };
            }
            return data;
          });
          return obj;
        });
        setPegaData(updatedPegadata);
        setInitProjectPlanList(updatedPegadata);
      }

      // Set the state with the updated array
    } else {
      if (ele === "Role") {
        if (rowData.data["Role"] !== value?.Name) {
          rowData.data["Assignee"] = "";
        }
      }
      if (ele === "Owner") {
        rowData.data["Assignee"] = value?.Name;
      } else {
        rowData.data[ele] = value?.Name;
      }

      // Create a new array with the updated data
      const updatedPegadata = pegadata.map((obj) => {
        obj.children.map((data) => {
          if (data.code === rowData.code) {
            return {
              ...data,
              data: {
                ...data.data,
                // [ele]: value?.Name,
                [ele === "Owner" ? "Assignee" : ele]: value?.Name,
              },
            };
          }
          return data;
        });
        return obj;
      });
      // Set the state with the updated array
      setPegaData(updatedPegadata);
      setInitProjectPlanList(updatedPegadata);
    }
    if (!isAccessEmpty) {
      setActiveSave(true);
    } else {
      setActiveSave(false);
    }
  };

  const onDDTyped = (rowData, val, ele) => {
    let value = val.value;
    if (typeof value === "string") {
      if (ele === "Role") {
        if (rowData.data["Role"] !== value) {
          rowData.data["Assignee"] = "";
        }
      }
      if (ele === "Owner") {
        rowData.data["Assignee"] = value;
      } else {
        rowData.data[ele] = value;
      }

      // const updatedPegadata = pegadata.map((obj) => {
      //   obj.children.map((data) => {
      //     if (data.code === rowData.code) {
      //       return {
      //         ...data,
      //         data: {
      //           ...data.data,
      //           [ele === "Owner" ? "Assignee" : ele]: value,
      //         },
      //       };
      //     }
      //     return data;
      //   });
      //   return obj;
      // });
      // if (rowData.data.Task !== "Define Design Intent" && !rowData.data.Task.includes("1).")) {
      //   setPegaData(updatedPegadata);
      //   setInitProjectPlanList(updatedPegadata);
      // }

      // Set the state with the updated array
    }
  };

  const onDurationChange = (rowData, { value }, ele) => {
    rowData.data[ele] = value < 1 ? "0" : value?.toString();

    const updatedPegadata = pegadata.map((obj) => {
      obj.children.map((data) => {
        if (data.code === rowData.code) {
          return {
            ...data,
            data: {
              ...data.data,
              ele: value,
            },
          };
        }
        return data;
      });
      return obj;
    });
    setPegaData(updatedPegadata);

    if (!isAccessEmpty) {
      setActiveSave(true);
    } else {
      setActiveSave(false);
    }
  };
  const onDate = (rowData, { value }, ele) => {
    rowData.data["Start_Date"] = changeDateFormat(value);

    const updatedPegadata = pegadata.map((obj) => {
      obj.children.map((data) => {
        if (data.code === rowData.code) {
          return {
            ...data,
            data: {
              ...data.data,
              ele: value,
            },
          };
        }
        return data;
      });
      return obj;
    });
    setTimeout(() => {
      setPegaData(updatedPegadata);
    }, 1000);
    setActiveSave(false);
  };

  useEffect(() => {
    const ProjectData = cloneDeep(projectPlan);
    let allCol = [];
    if (ProjectData.length) {
      allCol = Object.keys(ProjectData[0]);
    }
    let columnWidthProjectPlan = {};
    if (allCol.length) {
      allCol.forEach((column) => {
        columnWidthProjectPlan[column] = 100;
      });
    }

    let getJsonStoredWidthColumns = localStorage.getItem("columnWidthProjectPlan");
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      localStorage.setItem("columnWidthProjectPlan", JSON.stringify(columnWidthProjectPlan));
    }

    let jsonColWidth = localStorage.getItem("isColWidthSetMyProject");
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }

    childFunc.current = clearColumnWiseFilter;
  }, []);

  const dynamicColumns = () => {
    if (!isEmpty(allColumns)) {
      return allColumns.map((ele, i) => {
        return (
          <Column
            key={ele.field_Name}
            field={ele.field_Name === "Owner" ? "Assignee" : ele.field_Name}
            filterField={ele.field_Name}
            filterMatchMode="contains"
            filter={isSearch}
            filterPlaceholder={ele.field_Name}
            header={projectNameHeader(ele.field_Name, ele.column_Name, ele)}
            expander={ele.field_Name === "Task"}
            columnKey={ele.field_Name || i}
            frozen={ele.freeze}
            alignFrozen="left"
            className={
              ele?.field_Name === "Task" && ele.freeze
                ? "task-field-width fontBoldcolor"
                : ele?.field_Name === "Task"
                ? "task-field-width"
                : ele?.field_Name === "Role" && !ele.freeze
                ? "role-field-width"
                : ele.freeze
                ? "fontBoldcolor"
                : "cursorMove non-task-width"
            }
            showFilterMenu={false}
            body={elementTemplate}
          />
        );
      });
    }
  };

  const onSort = (column, direction, filterData) => {
    const sortedData = filterData?.length ? filterData : filters?.length ? filters : pegadata;
    let sortedDataSet = [];
    if (column === "Task") {
      sortedDataSet = SortAndFilterService.sortMultiLabelData(sortedData, column, direction);
    } else {
      sortedDataSet = SortAndFilterService.sortMultiColumnData(sortedData, column, direction);
    }
    setSortData([column, direction]);
    localStorage.setItem("sortingDataProjectPlanList", JSON.stringify(true));
    if (filterData?.length) {
      setFilters(sortedDataSet);
      selectedColumnList.current = sortedDataSet;
    } else {
      setPegaData(sortedDataSet);
    }
  };

  const [showApproveDialogCPPFA, setShowApproveDialogCPPFA] = useState(false);
  const [selectedTaskApproveDialogCPPFA, setSelectedTaskApproveDialogCPPFA] = useState([]);

  const handleApproveDialogCPPFA = (options) => {
    setShowApproveDialogCPPFA(true);
    let task = { TaskID: options.code, ProjectID: ProjectID };
    setSelectedTaskApproveDialogCPPFA(task);
    dispatch(getTaskDetails(options.code, ProjectID));
  };

  const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);

  return (
    <div className=" projectPlanTable myProjectAnddAllProjectList">
      {showApproveDialog && (
        <ApproveDesignDialog
          onClose={() => setShowApproveDialog(!showApproveDialog)}
          showTaskDialog={showApproveDialog}
          selectedTaskData={selectedTaskApproveDialog}
        />
      )}
      {showApproveDialogCPPFA && (
        <CPPFA
          onClose={() => setShowApproveDialogCPPFA(!showApproveDialogCPPFA)}
          showTaskDialog={showApproveDialogCPPFA}
          selectedTaskData={selectedTaskApproveDialogCPPFA}
          pegadata={designTabPegaData}
          getProjectPlanApi={getProjectPlanApi}
          TaskDetailsData={TaskDetailsData}
          userInformation={userInformation}
        />
      )}
      <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
        {view === "Tabular" && (
          <>
            <ACPFilter
              op={op}
              onSort={onSort}
              isTreeTable={true}
              sortData={sortData}
              pegadata={pegadata}
              setFilters={setFilters}
              setSortData={setSortData}
              projectData={flatPegadata}
              saveSettings={saveSettings}
              generateCustomOptions={true}
              pageName={"ProjectPlanLists"}
              frozenCoulmns={frozenCoulmns}
              selectedFields={selectedFields}
              selectAllColumnNotIncluded={true}
              selectedColumnName={selectedColumnName}
              onGlobalFilterChange={onGlobalFilterChange}
              clearColumnWiseFilter={clearColumnWiseFilter}
            />

            <ProjectPlanTable
              {...props}
              columns={dynamicColumns()}
              allColumns={allColumns}
              resizeMode={resizeMode}
            />

            {showTaskDialog && (
              <TaskDialog
                onClose={() => setShowTaskDialog(!showTaskDialog)}
                showTaskDialog={showTaskDialog}
                selectedTaskData={selectedTask}
                flag={flag}
              />
            )}
            <>
              {/* Dialog box to display the message */}
              <Dialog
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                header="Help Needed"
                modal
                className="MassDailog"
              >
                <div>
                  <span className="helpneed_dialog">Reason:</span>
                  <p className="mb-5">
                    {messageContent[1] === "undefined" ? "" : messageContent[1]}
                  </p>
                  <span className="helpneed_dialog">Comments:</span>
                  <p className="mb-5">
                    {messageContent[0] === "undefined" ? "" : messageContent[0]}
                  </p>
                </div>
              </Dialog>
              {/* Rest of your component */}
            </>
          </>
        )}
      </Suspense>
    </div>
  );
};

export default ProjectPlanList;
