import {
  Cancel,
  PmName,
  Import,
  UpdatePM,
  massUpdate,
  ProjectName,
  SaveChanges,
  newStartDate,
  Configuration,
  ManageCapacity,
  UploadLongTermPlan,
  InvalidDateMsg,
} from "../constant";
import "./AcpCommonDialog.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import Container from "react-bootstrap/Container";
import { AcpService } from "../../../service/ACPService";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import export2excel from "../../../assets/images/export2excel.svg";
import templateexcel from "../../../assets/files/Long_Term_Plan_File_Template.xlsx";
import templateExcelForExternalCap from "../../../assets/files/ExternalCapTemplate.xlsx";
import templateExcelForInternalCap from "../../../assets/files/InternalCapTemplate.xlsx";
import acpInfo from "../../../assets/images/acpInfo.svg";
import { Tooltip } from "primereact/tooltip";
import CapacityTable from "../ManageCapacity/CapacityTable";
import UploadLongTermFile from "../UploadLongTerm/UploadLongTermFile";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  importUpdateExternalCapacityData,
  importUpdateInternalCapacityData,
  updatePMPicklistData,
} from "../../../apis/acpApi";
import { AcpCommonService } from "../../../service/AcpCommonService";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";

function AcpBookingDialog({
  tabName,
  modalType,
  fetchData,
  selectedData,
  setEditedRow,
  setActionType,
  allEditedRows,
  setSelectedData,
  showAcpBookingDialog,
  setShowAcpBookingDialog,
  suppliers,
  pmPicklistData,
  dashboardPMTableDataRefresh,
  PM,
  user_group,
  showReportToast,
  setMainLoader,
}) {
  const [pm, setPM] = useState("");
  const pmNameSaved = localStorage.getItem("PmNameSaved");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pmValue, setPmValue] = useState({ code: "", name: "" });
  const newStartDatesSaved = localStorage.getItem("NewStartDatesSaved");
  const [importStatus, setImportStatus] = useState();
  const [importData, setImportData] = useState([]);
  const [fileReadStatus, setFileReadStatus] = useState(false);

  const [loader, setLoader] = useState(false);
  const [tableEdited, setTableEdited] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [capacityTableType, setCapacityTableType] = useState("External Capacity");
  const [minDate, setMinDate] = useState("");
  const [bookingData, setBookingData] = useState(
    selectedData && selectedData?.length ? [...selectedData] : []
  );
  const [formInValid, setFormInValid] = useState(true);

  const handleDateChange = (e) => {
    const selectedDate = e.value;
    setSelectedDate(selectedDate);
  };

  const submitCapacity = (type) => {
    if (type === "External Capacity") {
      let payload = {
        ManageExternalCapacity: {
          UserGroup: user_group,
          UpdatedExternalCapacity: editedRows,
        },
      };

      importUpdateExternalCapacityData(payload).then((data) => {
        fetchData();
        if (data && Object.keys(data)?.length) {
          setShowAcpBookingDialog(false);
          showReportToast("Capacity data Submitted");
          setMainLoader(true);
        } else {
          setShowAcpBookingDialog(false);
          showReportToast("Capacity data not Submitted", "error");
        }
      });
    } else {
      let payload = {
        ManageInternalCapacity: {
          UserGroup: user_group,
        },
        UpdatedInternalCapacities: editedRows,
      };

      importUpdateInternalCapacityData(payload).then((data) => {
        fetchData();
        if (data && Object.keys(data)?.length) {
          setShowAcpBookingDialog(false);
          showReportToast("Capacity data Submitted");
          setMainLoader(true);
        } else {
          setShowAcpBookingDialog(false);
          showReportToast("Capacity data not Submitted", "error");
        }
      });
    }
  };

  const handleMassUpdate = async () => {
    setActionType("massUpdate");
    const preSelectedRows =
      tabName === "dashboard"
        ? pmNameSaved && JSON.parse(pmNameSaved)
        : newStartDatesSaved && JSON.parse(newStartDatesSaved);

    tabName === "dashboard"
      ? selectedData.forEach((options) => {
          options.PmName = pm;
          options.PM = { code: pm, name: pm };
          // setEditedRow(options);
        })
      : selectedData.forEach((options) => {
          AcpService.handleDateChange(
            options,
            "StartDate",
            selectedDate,
            "slackValue",
            setEditedRow,
            allEditedRows,
            tabName
          );
        });
    const updatedSelectedData =
      tabName === "dashboard"
        ? selectedData.map((el) => {
            return { ...el, PmName: pm, PM: { code: pm, name: pm } };
          })
        : selectedData.map((el) => {
            return { ...el, StartDate: AcpService.convertDate(el.StartDate) };
          });
    const filteredSelectedData = AcpService.findUniqueObjects(
      preSelectedRows?.length ? preSelectedRows : [],
      updatedSelectedData,
      "UniqueID"
    );
    if (tabName === "booking") {
      localStorage.setItem("NewStartDatesSaved", JSON.stringify(filteredSelectedData));
    } else if (tabName === "dashboard") {
      const finalData = filteredSelectedData?.map((el) => {
        return {
          AWMProjectID: el?.AWMProjectID,
          PM: el?.PM?.name ? el?.PM?.name : el?.PmName,
        };
      });
      let obj = {};
      obj["NewPM"] = finalData;
      const pmStatus = await updatePMPicklistData(obj);

      if (pmStatus) {
        setPM("");
        setTableEdited(false);
        setPmValue({ code: "", name: "" });
        setTimeout(() => {
          fetchData();
        }, 1000);
      }
    }

    setShowAcpBookingDialog(false);
    setSelectedData([]);
  };
  const onFileClear = () => {
    setImportStatus();
  };

  const obj = {
    UpdatePM,
    massUpdate,
    // Configuration,
    ManageCapacity,
    UploadLongTermPlan,
  };

  const handleSelectedPmName = (value) => {
    if (pmPicklistData.includes(value)) {
      setPmValue(value);
      setPM(value?.name);
      setTableEdited(true);
    }
  };

  const handleCancel = () => {
    setShowAcpBookingDialog(false);
    setTableEdited(false);
    setPmValue();
    setPM();
    setEditedRows([]);
    setImportStatus();
  };

  useEffect(() => {
    setBookingData(() => selectedData?.map((item) => ({ ...item, allow: true })));
    AcpCommonService.checkMinDate(selectedData, setMinDate);
  }, [selectedData]);

  useEffect(() => {
    if (selectedDate && bookingData?.length > 0) {
      AcpCommonService.handleAllowDate({
        selectedDate,
        setData: setBookingData,
        startDate: "StartDate",
        setSataus: setFormInValid,
      });
    }
  }, [selectedDate]);

  return (
    <Dialog
      className={
        modalType === "ManageCapacity"
          ? "acp-dialog manage-cap"
          : modalType === "UploadLongTermPlan"
          ? "acp-dialog upload-long-term"
          : "acp-dialog"
      }
      style={{ width: "50vw" }}
      visible={showAcpBookingDialog}
      onHide={() => {
        setShowAcpBookingDialog(false);
        setImportStatus();
      }}
      header={
        <div className="p-dialog-acp">
          {modalType === "UploadLongTermPlan" ? (
            <>
              {obj[modalType]}
              <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
              <img
                className="infoIcon"
                data-pr-tooltip="Please upload CSV and excel file"
                src={acpInfo}
                alt="info"
              />
            </>
          ) : modalType === "ManageCapacity" ? (
            obj[modalType] +
            " " +
            `(${user_group[0]?.UserBU[0]?.["BU_Name"]}, ${user_group[0]?.UserRegion[0]?.["Region_Name"]})`
          ) : (
            obj[modalType]
          )}
        </div>
      }
    >
      <LoadingOverlay active={loader} spinner text="">
        <Container>
          {modalType === "massUpdate" || modalType === "UpdatePM" ? (
            <Row>
              <Col className="col-sm-8 popup-details">
                <label htmlFor="taskName" className="tasksname">
                  {ProjectName}
                </label>
                <div className="tasksname">
                  <ul>
                    {bookingData?.map((msg) => (
                      <li className="active-taskname" key={msg.ProjectName}>
                        {msg.ProjectName}
                        {!msg.allow && <span className="warning-text"> {InvalidDateMsg}</span>}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>

              {modalType === "massUpdate" ? (
                <Col className="col-sm-4">
                  <div className="start-date-wrapper">
                    <h6>{newStartDate}</h6>
                    <div className="acp-calendar">
                      <Calendar
                        showIcon={true}
                        dateFormat="d-M-y"
                        minDate={minDate}
                        value={selectedDate}
                        placeholder="Select"
                        onChange={handleDateChange}
                        disabledDays={[0, 6]}
                        icon={<CalendarIcon />}
                      />
                    </div>
                  </div>
                </Col>
              ) : (
                <Col className="col-sm-4">
                  <div className="start-date-wrapper">
                    <h6>{PmName}</h6>
                    <div>
                      <CommonAutocomplete
                        value={pmValue}
                        optionLabel="name"
                        className="w-full"
                        placeholder="Select PM"
                        suggestions={pmPicklistData}
                        onChange={(e) => {
                          handleSelectedPmName(e?.target?.value);
                        }}
                        field="name"
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          ) : modalType === "Configuration" ? (
            <>{Configuration}</>
          ) : modalType === "ManageCapacity" ? (
            <>
              <CapacityTable
                capacityTableType={capacityTableType}
                setCapacityTableType={setCapacityTableType}
                setEditedRows={setEditedRows}
                setTableEdited={setTableEdited}
                supplierValues={suppliers}
                PM={PM}
                setLoader={setLoader}
                userGroup={user_group}
              />
            </>
          ) : modalType === "UploadLongTermPlan" ? (
            <>
              <UploadLongTermFile
                onFileClear={onFileClear}
                importData={importData}
                setImportData={setImportData}
                fileReadStatus={fileReadStatus}
                setFileReadStatus={setFileReadStatus}
                setImportStatus={setImportStatus}
                dashboardPMTableDataRefresh={dashboardPMTableDataRefresh}
                setShowAcpBookingDialog={setShowAcpBookingDialog}
              />
            </>
          ) : null}
        </Container>

        <div
          className={
            tabName === "dashboard" && modalType === "ManageCapacity" ? "acp-dialog-footer" : ""
          }
        >
          {tabName === "dashboard" && modalType === "ManageCapacity" ? (
            <>
              <div className="acp-download-btn manage-cap-template">
                <a
                  rel="noreferrer"
                  href={
                    capacityTableType === "External Capacity"
                      ? templateExcelForExternalCap
                      : templateExcelForInternalCap
                  }
                  target="_blank"
                >
                  <img src={export2excel} alt="Download" />
                  Download Template
                </a>
              </div>
              <footer className="acp-footer-buttons">
                <Button type="btn" className="btn btn-secondary" onClick={handleCancel}>
                  {Cancel}
                </Button>

                <Button
                  type="btn"
                  onClick={() => submitCapacity(capacityTableType)}
                  className="btn btn-primary"
                  disabled={tabName === "dashboard" && !tableEdited ? true : false}
                >
                  {"Submit"}
                </Button>
              </footer>
            </>
          ) : (
            <footer className="mb-3 mt-4 massUpdateFooter">
              <Col className="col-sm-5 p-3">
                {modalType === "UploadLongTermPlan" ? (
                  <>
                    <div className="acp-download-btn">
                      <a href={templateexcel} target="_blank" rel="noreferrer">
                        <img src={export2excel} alt="Download" />
                        Download Template
                      </a>
                      <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
                      <img
                        className="infoIcon"
                        data-pr-tooltip="We have to select the file for upload (after downloading the template)"
                        src={acpInfo}
                        alt="info"
                      />
                    </div>
                  </>
                ) : null}
              </Col>

              <Col className="col-sm-7">
                <Row className="float-end">
                  <Col>
                    <Button type="btn" className="btn btn-secondary" onClick={handleCancel}>
                      {Cancel}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="btn"
                      onClick={
                        tabName === "dashboard" && modalType === "UploadLongTermPlan"
                          ? () => setFileReadStatus(true)
                          : handleMassUpdate
                      }
                      className="btn btn-primary"
                      disabled={
                        modalType === "massUpdate"
                          ? formInValid
                          : (tabName === "dashboard" || modalType === "UploadLongTermPlan"
                              ? !pm && !importStatus
                              : !selectedDate) &&
                            tabName === "dashboard" &&
                            !tableEdited
                      }
                      // disabled={tabName === "dashboard" && !tableEdited ? true : false}
                    >
                      {modalType === "UploadLongTermPlan"
                        ? Import
                        : modalType === "ManageCapacity"
                        ? "Submit"
                        : modalType === "UpdatePM"
                        ? "Update"
                        : SaveChanges}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </footer>
          )}
        </div>
      </LoadingOverlay>
    </Dialog>
  );
}

export default AcpBookingDialog;
